<template>
  <b-container class="mt-4">
    <collection-card :collection="collection" />

    <b-alert
      :show="dismissCountDown"
      dismissible
      @dismissed="dismissCountDown=0"
      variant="primary"
      style="position:fixed;bottom:7.5vh;right:15vw;width:300px;height:90px;"
    >
      <div href="#" class="">{{ alertMessage }}</div>
    </b-alert>

  </b-container>
</template>

<script>
import axios from 'axios';
import VueCookies from 'vue-cookies';
import CollectionCard from '../components/CollectionCard.vue'
// const API_URL = 'https://api-url-here.com?query=:query'
// const API_URL = 'http://localhost:8000/api/query=:query'

export default {
  name: 'EditCollectionPage',
  components: {
    CollectionCard,
  },
  data() {
    return {
      collection: {},

    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
  },
	mounted() {

	},
  created() {
    this.csrfToken = VueCookies.get('csrftoken')
    this.getCollection(`${process.env.VUE_APP_HOST}/api/collections/${this.$route.params.cuid}`)
  },
  watch: {
  },

  // http://localhost:8000/#/users/30fdc466-5486-44a8-b783-2e57c900feca

  methods: {
    getCollection(url) {
      axios.get(url)
      .then(response => {
				this.collection = response.data
      })
      .catch(e => {
				console.log(e)
        this.errors.push(e)
      })
    },


  },


}
</script>

<style lang="scss" scoped>
// @import '../../assets/custom.scss';

.board-name {
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
}

a {
  color:inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.profession {
  font-size:1.5rem;
  cursor: pointer;
  transition: background-color 0.5s ease;
  border-radius: 5px;

  &:hover {
    background: lightgray;
  }
}

.c-f {
  font-family: Larsseit;
}
.header-label {
  font-size: 1.5rem;
}

.prof-pill {
  background: lightblue;
  color: white;
  min-width:100px;
  cursor:pointer;
  border-radius:7px;
}

.input-label {
  color: #799eb2;
}

.custom-card {
  margin:1.25rem;
  padding:1.25rem;
  border-radius:7px;
  // border: 1px solid gray;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



</style>
