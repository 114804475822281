<template>
  <b-container class="mt-4">
    <h5 class="mb-5">Ooops, this page doesn't exist</h5>

  </b-container>
</template>

<script>
import VueCookies from 'vue-cookies';

export default {
  name: 'PathNotFound',
  components: {
  },
  data() {
    return {
			csrfToken: '',
      collection: {},
      permittedView: {},
    }
  },
  computed: {
  },
	mounted() {

  },
  created() {
    this.csrfToken = VueCookies.get('csrftoken')

  },
  watch: {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

span {
  font-weight: 400;
  font-family: Larsseit-Thin;
  // thin is thinner than light
  // font-family: Larsseit-Light;
}

.card {
  border-radius: 0.75rem;
}

.large {
  font-size: 1.5rem;
}

div, span {
  font-family: Larsseit-Light;
}

</style>
