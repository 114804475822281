

// export const stripePublishableKey = 'pk_test_51IC5saDb1IBq8XCC106OCrPLjCWIu9H7IIaKohrRAqKHiSn2KU92VyPWARXQE1OdqTCOVkNaxQRw7r0IRDqnMEKl00ggmza560';
let key = 'pk_test_51IC5saDb1IBq8XCC106OCrPLjCWIu9H7IIaKohrRAqKHiSn2KU92VyPWARXQE1OdqTCOVkNaxQRw7r0IRDqnMEKl00ggmza560';

if (process.env.VUE_APP_ENV == 'PROD') {
    console.log(`prod... ${key}`)
    console.log(key)
    key = 'pk_live_51IC5saDb1IBq8XCCY2P6YrzED5Ts4djeGt7pIjGWiBL6nLUK1NqYmTZ4soAEbBDQ9SWH8vmo4yVKf0hddjw41WtH00HLuiOJvG';
} else {
    console.log(`non-prod... ${key}`)
}

export const stripePublishableKey = key
