<template>
  <div>
    <h3>Terms of Services for Hints LLC</h3>

    <p>Welcome to Hints LLC ("Hints"), a software platform that allows users to publish information and charge visitors money in order to view that information. By accessing or using the Hints website or mobile application, you agree to be bound by these Terms and Services. Please read them carefully before using Hints.</p>

    <h5>Service Overview</h5>
    <p>Hints provides a platform for users to publish information and charge visitors money to view that information. Hints takes a small fee from the payment and sends the majority of the payment to the user who published the information.</p>

    <h5>User Responsibilities</h5>
    <p>As a user of Hints, you are responsible for ensuring that the information you publish is accurate and does not violate any laws or infringe on any intellectual property rights. You are also responsible for ensuring that you have the necessary rights to charge visitors money to view the information.</p>

    <h5>Prohibited Content</h5>
    <p>You may not publish any content on Hints that is fraudulent, defamatory, obscene, pornographic, or that promotes illegal activities. Hints reserves the right to remove any content that violates these Terms and Services or that is otherwise objectionable.</p>

    <h5>Payment Processing</h5>
    <p>Hints uses third-party payment processing services to process payments. Hints is not responsible for any errors or omissions made by these payment processing services. Hints may also use third-party services to verify the identity of users and to prevent fraud.</p>

    <h5>Refunds</h5>
    <p>Hints does not offer refunds for payments made to view information. However, if a user believes that they have been charged in error or that the information they paid for was fraudulent or otherwise misrepresented, they may contact Hints to request a refund.</p>

    <h5>Intellectual Property</h5>
    <p>Hints respects the intellectual property rights of others and expects its users to do the same. Users may not publish any information on Hints that infringes on any intellectual property rights, including copyrights, trademarks, or patents.</p>

    <h5>Privacy</h5>
    <p>Hints collects and uses personal information in accordance with its Privacy Policy. By using Hints, you agree to the collection and use of your personal information as described in the Privacy Policy.</p>

    <h5>Limitation of Liability</h5>
    <p>Hints is not responsible for any damages or losses that result from the use of its platform. Hints does not guarantee the accuracy or reliability of any information published on its platform. Hints is not responsible for any errors or omissions made by users or by third-party payment processing services.</p>

    <h5>Indemnification</h5>
    <p>You agree to indemnify and hold Hints harmless from any claims, damages, or expenses that result from your use of the platform or from the information that you publish on the platform.</p>

    <h5>Termination</h5>
    <p>Hints reserves the right to terminate the account of any user who violates these Terms and Services or who engages in any fraudulent or illegal activity. Hints may also terminate the accounts of users who receive multiple complaints from visitors or who receive low ratings from visitors.</p>

    <h5>Governing Law</h5>
    <p>These Terms and Services are governed by the laws of the state of [State] without regard to its conflict of law provisions.</p>

    <h5>Amendments</h5>
    <p>Hints reserves the right to amend these Terms and Services at any time without prior notice. Any changes will be posted on the Hints website or mobile application. Your continued use of Hints after any changes have been made constitutes your acceptance of the new Terms and Services.</p>

    <p>If you have any questions or concerns about these Terms and Services, please contact us at [contact email].</p>




  </div>
</template>


<script>

export default {
  // eslint-disable-next-line
  name: 'Terms',
  components: {

  },
  data() {
    return {
    }
  },
  computed: {

  },
	mounted() {

  },
  created() {
    // this.getCollectionViews(`${process.env.VUE_APP_HOST}/api/users/${this.$route.params.uuid}/`)
    // this.getViews(`${process.env.VUE_APP_HOST}/api/users/${this.currentUser.uuid}/views/`)
  },

}
</script>

<style lang="scss" scoped>


</style>
