<template>
  <div class="article-card my-3 p-3 box-shadow">
    <div class="article-corner" style="position:relative;">
      <ArticleSvg
        style="position:absolute;right:0;height:auto;"
        color="rgb(109, 183, 237)"
        size="2.5rem"
        class="d-inline-block mr-1 mb-3"
      />
      <div class="article-banner px-2 py-1" style="position:absolute;right:0;top: 3rem;">article</div>
    </div>
    <div class="article-title">{{ article.title }}</div>
    <div class="article-title mb-3">{{ article.created_at }}</div>
    <div class="article-body mb-3" v-html="article.body"></div>
    <!-- <b-icon
      style="position:absolute;right:4rem;bottom:2rem;cursor:pointer;"
      font-scale="1.25"
      icon="pencil-square"
      @click="edit()"
    /> -->
    <div class="custom-card-footer" style="height:40px;position:relative;">
      <b-icon
        style="position:absolute;right:0rem;bottom:0rem;cursor:pointer;"
        font-scale="1.25"
        icon="trash"
        @click="remove()"
      />
    </div>
  </div>
</template>

<script>
import ArticleSvg from '../../assets/icons/ArticleSvg.vue'

export default {
  name: 'ArticleCard',
  components: {
    ArticleSvg,

  },
  props: [
    'article',
  ],
  data() {
    return {


    }
  },
  computed: {
    // currentUser() {
      // return this.$store.state.currentUser
    // },
  },
	mounted() {

	},
  created() {

  },
  watch: {
    // optionsSearch: _.debounce(function(addr) { this.getOptions(addr) }, 500), // worked before..
  },

  methods: {
    remove() {
      this.$emit('remove-article-from-collection')
    },
    // edit() {
    //   this.$emit('edit-referral')
    // }
  }
}
</script>

<style lang="scss" scoped>

.article-card {
  border-radius: 5px;
  background:white;
  // background:lightgreen;
}
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.input-label {
  font-size: 1.5rem;
  font-weight: 600;
}
.c-f {
  font-family: Larsseit;
}

.article-title {
  font-size:1.25rem;
}

div {
  font-family: Larsseit-Light;
}

</style>
