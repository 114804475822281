<template>
  <div>
    <svg :width="size" :height="size" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m600 154.7c122.98 0 234.31 49.824 314.89 130.45 80.578 80.578 130.45 191.91 130.45 314.89 0 122.98-49.824 234.31-130.45 314.89-80.578 80.578-191.91 130.45-314.89 130.45s-234.31-49.824-314.89-130.45c-80.578-80.578-130.45-191.91-130.45-314.89 0-122.98 49.824-234.31 130.45-314.89 80.578-80.578 191.91-130.45 314.89-130.45zm295.02 150.32c-75.516-75.516-179.8-122.18-295.02-122.18-115.21 0-219.5 46.703-295.02 122.18-75.516 75.516-122.18 179.8-122.18 295.02 0 115.21 46.703 219.5 122.18 295.02 75.516 75.516 179.8 122.18 295.02 122.18 115.21 0 219.5-46.703 295.02-122.18 75.516-75.516 122.18-179.8 122.18-295.02 0-115.21-46.703-219.5-122.18-295.02z"/>
        <path d="m922.48 682.48c-18.52 72.605-60.879 135.68-118.25 180.31-56.406 43.875-127.24 70.031-204.23 70.031-76.953 0-147.83-26.156-204.23-70.031-57.586-44.848-100.07-108.21-118.46-181.2-1.8984-7.5078 2.6992-15.145 10.211-17.043 1.1406-0.29688 2.2773-0.42188 3.418-0.42188h618.14c7.7617 0 14.047 6.2852 14.047 14.047 0 1.4766-0.25391 2.9531-0.67578 4.3047zm-135.51 158.12c48.094-37.422 84.672-88.977 103.53-148.33h-580.97c18.859 59.402 55.395 110.91 103.53 148.33 51.598 40.164 116.48 64.082 186.98 64.082 70.496 0 135.38-23.922 186.98-64.082z"/>
        <path d="m806.17 345.39c5.9922-4.8945 14.852-3.9648 19.703 2.0234 4.8945 5.9922 3.9648 14.852-2.0234 19.703l-89.188 72.859 89.188 72.859c5.9922 4.8945 6.918 13.711 2.0234 19.703-4.8945 5.9922-13.711 6.918-19.703 2.0234l-102.3-83.617c-0.42188-0.33594-0.80078-0.67578-1.2227-1.0547l-0.085937-0.085937c-1.0117-1.0117-1.8555-2.1953-2.4883-3.418v-0.042969c-0.042968-0.085937-0.085937-0.16797-0.125-0.25391l-0.042968-0.125-0.085938-0.16797c-0.042968-0.085937-0.042968-0.125-0.085937-0.21094l-0.042969-0.085938c-0.67578-1.5625-1.0547-3.207-1.1406-4.8516v-0.29688-0.085938-0.25391-0.33594-0.25391-0.085937-0.29688c0.085937-1.6445 0.46484-3.2891 1.1406-4.8516l0.042969-0.085937c0.042969-0.085938 0.042969-0.125 0.085937-0.21094l0.085938-0.16797 0.042968-0.125c0.042969-0.085937 0.085938-0.16797 0.125-0.25391v-0.042968c0.63281-1.2227 1.4766-2.3633 2.4883-3.418l0.085937-0.085938c0.37891-0.37891 0.80078-0.75781 1.2227-1.0547l102.3-83.617z"/>
        <path d="m421.33 534.61c-5.9922 4.8945-14.852 3.9648-19.703-2.0234-4.8945-5.9922-3.9648-14.852 2.0234-19.703l89.188-72.859-89.188-72.859c-5.9922-4.8945-6.918-13.711-2.0234-19.703 4.8945-5.9922 13.711-6.918 19.703-2.0234l102.3 83.617c0.42188 0.33594 0.80078 0.67578 1.2227 1.0547l0.085937 0.085937c1.0117 1.0117 1.8555 2.1953 2.4883 3.418v0.042969c0.042968 0.085938 0.085937 0.16797 0.125 0.25391l0.042968 0.125 0.085938 0.16797c0.042969 0.085937 0.042969 0.125 0.085938 0.21094l0.042968 0.085938c0.67578 1.5625 1.0547 3.207 1.1406 4.8516v0.29688 0.085937 0.25391 0.33594 0.25391 0.085937 0.29688c-0.085937 1.6445-0.46484 3.2891-1.1406 4.8516l-0.042968 0.085938c-0.042969 0.085937-0.042969 0.125-0.085938 0.21094l-0.085938 0.16797-0.042968 0.125c-0.042969 0.085937-0.085938 0.16797-0.125 0.25391v0.042969c-0.63281 1.2227-1.4766 2.3633-2.4883 3.418l-0.085937 0.085937c-0.37891 0.37891-0.80078 0.75781-1.2227 1.0547l-102.3 83.617z"/>
      </g>
    </svg>
  </div>
</template>


<script>

export default {
  name: 'SmileySvg',
  props: [
    'color',
    'size'
  ],
  components: {
  }
}


</script>
