<template>
  <div>
    <svg
      :width="size"
      :height="size"
      version="1.1"
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path :fill="color" d="m457.2 300h-1.1992l-3.6016-1.1992c27.602-28.801 43.199-67.199 43.199-109.2 0-87.602-70.801-158.4-158.4-158.4-87.602 0-158.4 70.801-158.4 158.4 0 42 16.801 80.398 43.199 109.2l-6 1.1992c-66 24-111.6 81.602-120 151.2l-14.398 141.6c0 6 1.1992 12 4.8008 15.602 3.6016 4.8008 9.6016 7.1992 15.602 7.1992h115.2l31.199-40.801h-123.6l12-118.8c6-54 42-98.398 92.398-117.6l33.602-9.6016c21.602 12 48 18 74.398 18 26.398 0 51.602-7.1992 74.398-18l32.398 9.6016c51.602 19.199 86.398 63.602 93.602 117.6l12 118.8h-123.6l31.199 40.801h115.2c6 0 10.801-2.3984 15.602-7.1992 3.6016-4.8008 6-9.6016 4.8008-15.602l-14.398-141.6c-8.4023-69.598-55.203-128.4-121.2-151.2zm-237.6-111.6c0-64.801 52.801-117.6 117.6-117.6s117.6 52.801 117.6 117.6c0 64.801-52.801 117.6-117.6 117.6-64.805 0-117.6-52.801-117.6-117.6z"/>
        <path :fill="color" d="m1104 1005.6c-8.3984-69.602-54-128.4-120-151.2h-1.1992l-4.8008-2.3984c27.602-28.801 44.398-67.199 44.398-109.2 0-87.602-70.801-158.4-158.4-158.4-87.602 0-158.4 70.801-158.4 158.4 0 42 16.801 80.398 44.398 109.2l-6 1.1992c-66 24-112.8 81.602-120 151.2l-14.398 141.6c-1.1992 6 1.1992 12 4.8008 15.602 3.6016 4.8008 9.6016 7.1992 15.602 7.1992h470.4c6 0 10.801-2.3984 15.602-7.1992 3.6016-4.8008 6-9.6016 4.8008-15.602zm-241.2-380.4c64.801 0 117.6 52.801 117.6 117.6s-52.801 117.6-117.6 117.6-117.6-52.801-117.6-117.6 52.801-117.6 117.6-117.6zm-212.4 504 12-118.8c6-54 42-98.398 92.398-116.4l33.602-9.6016c21.602 12 46.801 18 74.398 18 26.398 0 51.602-7.1992 74.398-18l33.602 9.6016c51.602 18 86.398 63.602 93.602 117.6l12 118.8-426-0.003906z"/>
        <path :fill="color" d="m354 546c-7.1992-10.801-25.199-10.801-33.602 0l-199.2 267.6c-4.8008 6-4.8008 14.398-2.3984 21.602 3.6016 7.1992 10.801 10.801 18 10.801l103.2-0.003906c18 156 139.2 280.8 279.6 283.2 8.3984 0 15.602-4.8008 19.199-12 3.6016-7.1992 2.3984-15.602-3.6016-21.602-54-62.398-90-153.6-98.398-248.4h98.398c7.1992 0 14.398-4.8008 18-10.801 3.6016-7.1992 2.3984-15.602-2.3984-21.602zm61.199 259.2c-6 0-10.801 2.3984-14.398 6-3.6016 3.6016-6 9.6016-6 15.602 3.6016 92.398 32.398 184.8 79.199 255.6-104.4-25.199-188.4-132-194.4-256.8-1.1992-10.801-9.6016-19.199-20.398-19.199h-80.398l158.4-212.4 158.4 212.4-80.402-0.003906z"/>
        <path :fill="color" d="m862.8 520.8c135.6 0 244.8-110.4 244.8-244.8 0-134.4-109.2-246-244.8-246-135.6 0-244.8 110.4-244.8 244.8 0 134.4 109.2 246 244.8 246zm0-450c112.8 0 204 91.199 204 204s-91.199 204-204 204-204-91.199-204-204 91.199-204 204-204z"/>
        <path :fill="color" d="m814.8 361.2c3.6016 3.6016 9.6016 6 14.398 6 6 0 10.801-2.3984 14.398-6l136.8-136.8c8.3984-8.3984 8.3984-21.602 0-28.801-8.3984-8.3984-21.602-8.3984-28.801 0l-121.2 122.4-56.398-56.398c-8.3984-8.3984-21.602-8.3984-28.801 0-8.3984 8.3984-8.3984 21.602 0 28.801z"/>
      </g>
    </svg>
  </div>
</template>


<script>

export default {
  name: 'ReferralSvg',
  props: [
    'color',
    'size'
  ],
  components: {
  }
}


</script>
