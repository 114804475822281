<template>
  <div class="referral-card my-3 p-3 box-shadow">
    <div class="referral-corner" style="position:relative;">
      <ReferralSvg
        style="position:absolute;right:0;height:auto;"
        color="rgb(109, 183, 237)"
        size="2.5rem"
        class="d-inline-block mr-1 mb-3"
      />
      <!-- <div class="article-banner px-2 py-1" style="position:absolute;right:0;top: 3rem;">referral</div> -->
    </div>

    <div class="bold c-f profession-name">{{ referral.profession_name }}</div>
    <div class="">{{ referral.name }}</div>
    <!-- <div class="" v-if="referral.email">{{ referral.email }}</div> -->
    <!-- <div class="" v-if="referral.phone_number">{{ referral.phone_number }}</div> -->
    <!-- <div class="" v-if="referral.zip_code">Zipcode: {{ referral.zip_code }}</div> -->
    <div class="" v-if="referral.state">Location: {{ referral.state }}</div>
    <!-- <div class="" v-if="referral.website_url">Website: {{ referral.website_url }}</div> -->
    <div
      v-if="referral.permitted_elsewhere"
      class="info-corner mt-3"
      style="position:relative; display:block; width:100%; height:50px;background:yellow;"
    >
        <!-- <ReferralSvg
          style="position:absolute;right:0;bottom:0;height:auto;"
          color="rgb(109, 183, 237)"
          size="2.5rem"
          class="d-inline-block mr-1 mb-3"
        /> -->
        <div style="position:absolute;right:0;bottom:0;height:auto;">NOTE! you have already purchased this referral in another one of this person's collections. Proceed at your own risk ;)</div>
        <!-- <div class="article-banner px-2 py-1" style="position:absolute;right:0;top: 3rem;">referral</div> -->
    </div>
  </div>
</template>

<script>
import ReferralSvg from '../../assets/icons/ReferralSvg.vue'

export default {
  name: 'ReferralCard',
  components: {
    ReferralSvg,
  },
  props: [
    'referral'
  ],
  data() {
    return {


    }
  },
  computed: {
    // currentUser() {
      // return this.$store.state.currentUser
    // },
  },
	mounted() {

	},
  created() {

  },
  watch: {
    // optionsSearch: _.debounce(function(addr) { this.getOptions(addr) }, 500), // worked before..
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

.referral-card {
  border-radius: 5px;
  // background:lightgreen;
}
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.input-label {
  font-size: 1.5rem;
  font-weight: 600;
}
.c-f {
  font-family: Larsseit;
}

.profession-name {
  font-size:1.25rem;
}

div {
  // font-weight: 100;
  font-family: Larsseit-Light;
}

</style>
