<template>
  <div>
    <svg :width="size" :height="size" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path :fill="color" d="m1131.2 35.945h-1062.5c-18.094 0-32.812 14.719-32.812 32.812v1062.5c0 18.094 14.719 32.812 32.812 32.812h1062.5c18.094 0 32.812-14.719 32.812-32.812l-0.003906-1062.5c0-18.094-14.719-32.812-32.812-32.812zm-1062.5 28.125h1062.5c2.5859 0 4.6875 2.1016 4.6875 4.6875v151.55h-1071.9v-151.55c0-2.5898 2.0977-4.6875 4.6875-4.6875zm1062.5 1071.9h-1062.5c-2.5859 0-4.6875-2.1016-4.6875-4.6875v-882.8h1071.9v882.8c-0.003906 2.5898-2.1016 4.6875-4.6914 4.6875z"/>
        <path :fill="color" d="m957.04 156.24h75c7.7617 0 14.062-6.3008 14.062-14.062 0-7.7617-6.3008-14.062-14.062-14.062h-75c-7.7617 0-14.062 6.3008-14.062 14.062 0 7.7656 6.2969 14.062 14.062 14.062z"/>
        <path :fill="color" d="m769.54 156.24h75c7.7617 0 14.062-6.3008 14.062-14.062 0-7.7617-6.3008-14.062-14.062-14.062h-75c-7.7617 0-14.062 6.3008-14.062 14.062 0 7.7656 6.2969 14.062 14.062 14.062z"/>
        <path :fill="color" d="m1023.8 406.26h-262.5c-7.7617 0-14.062 6.3008-14.062 14.062v262.48c0 7.7617 6.3008 14.062 14.062 14.062h262.5c7.7617 0 14.062-6.3008 14.062-14.062v-262.48c0-7.7656-6.2812-14.062-14.062-14.062zm-14.062 262.48h-234.38v-234.36h234.38z"/>
        <path :fill="color" d="m176.18 434.38h350.01c7.7617 0 14.062-6.3008 14.062-14.062 0-7.7617-6.3008-14.062-14.062-14.062h-350.01c-7.7617 0-14.062 6.3008-14.062 14.062 0 7.7617 6.2812 14.062 14.062 14.062z"/>
        <path :fill="color" d="m176.18 565.63h428.14c7.7617 0 14.062-6.3008 14.062-14.062 0-7.7617-6.3008-14.062-14.062-14.062h-428.14c-7.7617 0-14.062 6.3008-14.062 14.062 0 7.7617 6.2812 14.062 14.062 14.062z"/>
        <path :fill="color" d="m176.18 696.86h281.25c7.7617 0 14.062-6.3008 14.062-14.062s-6.3008-14.062-14.062-14.062h-281.25c-7.7617 0-14.062 6.3008-14.062 14.062s6.2812 14.062 14.062 14.062z"/>
        <path :fill="color" d="m176.18 828.11h746.89c7.7617 0 14.062-6.3008 14.062-14.062s-6.3008-14.062-14.062-14.062h-746.89c-7.7617 0-14.062 6.3008-14.062 14.062s6.2812 14.062 14.062 14.062z"/>
        <path :fill="color" d="m1023.8 931.24h-847.65c-7.7617 0-14.062 6.3008-14.062 14.062s6.3008 14.062 14.062 14.062h847.67c7.7617 0 14.062-6.3008 14.062-14.062s-6.3008-14.062-14.082-14.062z"/>
      </g>
    </svg>
  </div>
</template>


<script>

export default {
  name: 'ContentSvg',
  props: [
    'color',
    'size'
  ],
  components: {
  }
}


</script>
