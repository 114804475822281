<template>
  <div>
    <b-card class="mb-3">
      <div class="mb-1">This collection consists of:</div>
      <div class="mb-4" style="font-size:1.25rem;">{{  selectedCollection.referrals.map((e) => e.profession_name).join(", ") }}</div>
      <div class="my-2">It costs <span style="font-size:2rem;color:rgb(109, 183, 237);">${{  selectedCollection.price_in_dollars }}</span> to access</div>
    </b-card>

      <div
      v-if="!currentUser.authenticated"
      class="form-item mb-3"
      >
      <div>Enter email</div>
      <b-form-input
        v-model="visitorEmail"
        placeholder="phil@hellohints.com">
      </b-form-input>
    </div>
    <div
      v-if="showPaymentEmailErrorMessage"
      class="error-message my-3"
    >{{ paymentEmailErrorMessage }}</div>

    <div>
      <p>By clicking below to make this purchase, you agree to be bound by the <a target="_blank" href="https://hellohints.com/about#/purchaser-terms">Hints Purchaser Terms</a>. This is a one-time purchase. There is no subscription.</p>
    </div>
    <b-button
      v-if="!paymentIntentCreated"
      @click="createPaymentIntent()"
      variant="primary"
      class="mb-3"
    >I want to buy this!</b-button>

    <form id="payment-form">
      <div id="payment-element">
        <!-- Elements will create form elements here -->
      </div>
      <button
        v-if="paymentIntentCreated"
        id="submit"
        class="btn btn-primary mt-3"
        variant="primary"
      >Purchase on Hints
      </button>
      <div id="error-message">
        <!-- Display error message to your customers here -->
      </div>
    </form>

    <div
      v-if="modalMessage"
      style="font-size:1.5rem;"
      class="my-3"
    >
      {{ modalMessage }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueCookies from 'vue-cookies';
import { stripePublishableKey } from '../constants/keys'
// import { createAnalyticsEvent } from '../hooks/helperFunctions'
// import ReferralSvg from '../../assets/icons/ReferralSvg.vue'

export default {
  name: 'PaymentDetails',
  components: {

  },
  props: [
    'selectedCollection',
    'paymentSetupComplete'
  ],
  data() {
    return {


      // STRIPE
      visitorUuid: 0,
      visitorEmail: '',
      stripeUuid: '',
      paymentIntentCreated: false,
      orderNumber: '',
      showPaymentEmailErrorMessage: false,
      paymentEmailErrorMessage: 'Please enter your account email, or the email you want to receive the collections to',
      paymentClientSecret: '',

    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
  },
	mounted() {
    console.log('thisselectedcollection')
    console.log(this.selectedCollection)
	},
  created() {
    console.log('thisselectedcollection')
    console.log(this.selectedCollection)
  },
  watch: {
    // optionsSearch: _.debounce(function(addr) { this.getOptions(addr) }, 500), // worked before..
  },

  methods: {


    async createPaymentIntent() {
      if (this.currentUser.authenticated) {
        this.visitorEmail = this.currentUser.email
        this.visitorUuid = this.currentUser.uuid
      }

      if (!this.paymentSetupComplete) {
        console.log('ehh1 ?')
        this.paymentEmailErrorMessage = 'This user does not have their bank account set up. We have emailed them to let them know you want access!'
        this.showPaymentEmailErrorMessage = true

      } else if (this.visitorEmail == '') {
        console.log('ehh2 ?')
        this.paymentEmailErrorMessage = 'Please enter your account email, or the email you want to receive the collections to',
        this.showPaymentEmailErrorMessage = true


      } else {
        console.log('ehh3 ?')
        this.paymentIntentCreated = true

        console.log(this.selectedCollection)
        console.log(this.selectedCollectionPrice)

        const paymentTokenUuid = crypto.randomUUID()
        console.log(paymentTokenUuid)

        // TODO ASAP!! set this to datetime here - send it to my backend -
        // when i look for permittedView - double check the payment token
        VueCookies.set('paymentSuccesstoken', paymentTokenUuid) //

        // IMPORTANT:
        // Caution
        // If you have tooling that tracks the customer’s browser session, you might need to add the stripe.com domain to the referrer exclude list. Redirects cause some tools to create new sessions, which prevents you from tracking the complete session.
        const payload = {
          visitor_uuid: this.visitorUuid,
          visitor_email: this.visitorEmail,
          selected_collection: this.selectedCollection,
          browser_payment_token_uuid: paymentTokenUuid,
        }
        const res = await axios.post(`${process.env.VUE_APP_HOST}/api/create_stripe_payment_intent/`, payload, {headers: { "content-type": "application/json", 'X-CSRFToken': this.csrfToken}})
        // console.log('heyoo! PAYMENT INTENT')
        // console.log(res)
        this.paymentClientSecret = res.data.client_secret
        this.orderNumber = res.data.payment_intent_order_number

        this.renderStripePaymentForm()
        // const body = res.data
        // window.location.href = body.url

      }
    },

    renderStripePaymentForm() {
      console.log('rendering stripe payment 1')
      // might need to do this.. from here:
      // https://stripe.com/docs/connect/enable-payment-acceptance-guide?platform=web&ui=payment-element&client=html#accept-payment

      // Initialize Stripe.js with the same connected account ID used when creating
      // the PaymentIntent.
      // const stripe = Stripe(''', {
      //   stripeAccount: '{{CONNECTED_ACCOUNT_ID}}'
      // });

      // Can be publicly accessible in your web or mobile app’s client-side code
      // https://stripe.com/docs/keys
      const publishableKey = stripePublishableKey
      const stripe = window.Stripe(publishableKey);
      // console.log('rendering payment form! using key', publishableKey)


      let options = {
        clientSecret: this.paymentClientSecret,
        // appearance: appearance,   // Fully customizable with appearance API.
      };

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
      let elements = stripe.elements(options);

      // Create and mount the Payment Element
      let paymentElement = elements.create('payment');
      paymentElement.mount('#payment-element');

      // Render Payment form
      const form = document.getElementById('payment-form');
      console.log('rendering stripe payment 4')

      form.addEventListener('submit', async (event) => {
        event.preventDefault();
        const {error} = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: `${process.env.VUE_APP_HOST}/#/order/${this.orderNumber}/complete`
          },
        });

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          const messageContainer = document.querySelector('#error-message');
          messageContainer.textContent = error.message;
        } else {

          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      });

      // get_or_create_permitted_view
      // this must be someone "logged out"- goes to UserApp vs MarketingApp...

    },

  }
}
</script>

<style lang="scss" scoped>

.error-message {
  color: #e44436;
  font-size: 0.85rem;
  margin-top:0.5rem;
  margin-bottom:0.5rem;
}


</style>
