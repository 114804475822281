<template>
  <div class="item-card  my-3 p-3 box-shadow">
    <div class="c-f profession-name">{{ item.title }}</div>
    <div v-html="item.description"></div>
    <!-- <div>{{ item.description }}</div> -->
    <span v-if="isViewable" class="gallery-container">
      <b-img
        v-for="img in item.images"
        v-bind:key="img.id"
        variant="info"
        fluid
        alt=""
        :src="img.url"
        width="200px"
        height="auto"
        class="mb-2 gallery-item"
      />
    </span>
    <span v-else class="gallery-container">
      <div
        style="border:1px solid lightgray; border-radius:5px;text-align: center;padding:1rem;"
        v-for="img in item.images"
        v-bind:key="img.id"
        class="d-inline-block mr-2"
      >
        <LockedImageSvg
        style="height:auto;"
        color="rgb(109, 183, 237)"
        size="10rem"
        />
      </div>
    </span>
  </div>
</template>

<script>
import LockedImageSvg from '../../assets/icons/LockedImageSvg.vue'

export default {
  name: 'ItemCard',
  components: {
    LockedImageSvg,

  },
  props: [
    'item',
    'isViewable'
  ],
  data() {
    return {


    }
  },
  computed: {
    // currentUser() {
      // return this.$store.state.currentUser
    // },
  },
	mounted() {

	},
  created() {

  },
  watch: {
    // optionsSearch: _.debounce(function(addr) { this.getOptions(addr) }, 500), // worked before..
  },

  methods: {
    remove() {
      // this.$emit('remove-item-from-collection')
    },
    edit() {
      // this.$emit('edit-referral')
    }

  }
}
</script>

<style lang="scss" scoped>

.gallery-item {
  display: inline-block;
}

.gallery-container {
  display: flex;
  flex-direction: row;
  height: 200px;
  overflow-x: scroll;
}

.item-card {
  border-radius: 5px;
  background:white;
  // background:lightgreen;
}
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.input-label {
  // font-size: 1.5rem;
  // font-weight: 600;
}
.c-f {
  font-family: Larsseit;
}

.profession-name {
  font-size:1.25rem;
}

div {
  // font-weight: 100;
  font-family: Larsseit-Light;
}

</style>
