<template>
  <div>
    <svg :width="size" :height="size" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m267.49 358.17c-5.6562 0.074219-7.5586 4.5-7.5586 7.5586v423.2c0 3.957 3.6016 7.5547 7.5586 7.5586h491.21v37.785c0 3.957 3.6016 7.5547 7.5586 7.5586h166.26c3.957 0 7.5586-3.6016 7.5586-7.5586v-120.91c0-3.957-3.6016-7.5547-7.5586-7.5586h-22.672v-30.23c0-18.52-6.6016-34.133-17.711-44.633-7.5742-7.1602-17.156-11.871-27.629-14.168v-251.04c0-3.957-3.6016-7.5547-7.5586-7.5586zm7.5586 15.113h574.34v241.83c-16.32 0-31.637 5.3242-42.746 15.824-8.332 7.875-14.152 18.711-16.531 31.41l-86.438-90.211c-1.7773-1.8164-4.3516-2.6172-6.8477-2.1289-1.3281 0.30078-2.5547 0.95313-3.543 1.8906l-112.65 98.715-141.22-158.94c-1.7344-1.8984-4.3125-2.7891-6.8516-2.3594-1.6055 0.23047-3.0977 0.97656-4.25 2.125l-153.27 160.36v-298.51zm309.84 45.344c-29.125 0-52.898 23.773-52.898 52.898s23.773 52.898 52.898 52.898 52.898-23.773 52.898-52.898-23.773-52.898-52.898-52.898zm0 15.113c20.957 0 37.785 16.828 37.785 37.785 0 20.957-16.828 37.785-37.785 37.785s-37.785-16.828-37.785-37.785c0-20.957 16.828-37.785 37.785-37.785zm-151.38 94.23 225.3 253.4h-383.76v-87.617l158.46-165.78zm264.26 59.984 91.156 95.41v22.438h-24.09c-3.4648 0.65625-6.2031 4.0312-6.1406 7.5586v68.016h-79.586l-88.562-99.66 107.22-93.758zm151.61 42.273c12.984 0 24.34 4.2305 32.355 11.809 8.0156 7.5742 12.988 18.562 12.988 33.535v30.23h-90.688v-30.23c0-14.973 4.9727-25.957 12.988-33.535 8.0156-7.5742 19.367-11.809 32.355-11.809zm-75.57 90.688h151.14v105.8h-151.14z"/>
      </g>
    </svg>
  </div>
</template>


<script>

export default {
  name: 'LockedImageSvg',
  props: [
    'color',
    'size'
  ],
  components: {
  }
}


</script>
