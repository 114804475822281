<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand href="/#/">Hints</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/#/">Dashboard</b-nav-item>
          <b-nav-item href="/#/collections">Collections</b-nav-item>
          <b-nav-item href="/#/referrals">Library</b-nav-item>
          <b-nav-item href="/#/new">Create</b-nav-item>
          <b-nav-item href="/#/search">Search</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-item>{{ parent }}</b-nav-item> -->
          <b-nav-item v-if="testEnvironment()" style="background:lightsalmon;">STAGING</b-nav-item>
          <b-nav-item href="/#/onboard">onboard</b-nav-item>
          <b-nav-item href="/#/views">Views</b-nav-item>
          <b-nav-item href="/#/purchases">Purchases</b-nav-item>
          <b-nav-item-dropdown v-if="currentUser" :text="currentUser.full_name || currentUser.email" right>
            <b-dropdown-item :href="currentUser.public_share_link">Profile</b-dropdown-item>
            <b-dropdown-item href="/#/settings">Settings</b-dropdown-item>
            <b-dropdown-item v-if="currentUser.authenticated" href="/api-auth/logout/?next=/accounts/login">Log out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>

    <!-- <profile-wizard @finish-wizard="finishWizard()" :show="showWizard" /> -->

  </div>
</template>


<script>
// import axios from 'axios'
// import VueCookies from 'vue-cookies'
// import ProfileWizard from './simplifi/ProfileWizard.vue'

export default {
  name: 'NavBar',
  data() {
    return {
      username: '',
      authenticated: false,
      notifications: [],
      // showWizard: false,
    }
  },
  components: {
    // ProfileWizard,
  },
  props: [
    'parent'
  ],
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
  },
  created() {
    // console.log(`envs ${process.env}`)

    // const csrfToken = VueCookies.get('csrftoken')
    // const postHeaders = { "content-type": "application/json", 'X-CSRFToken': csrfToken }
    // const url = `${process.env.VUE_APP_HOST}/api/get-current-info/`
    // console.log(`navbar url calling ${url}`)

    // axios.get(url, postHeaders)
    // .then(response => {
    //   this.$store.commit('setCurrentUser', response.data)
    //   this.$store.commit('setSavedContents', response.data.profile.saved_contents)
    //   this.username = response.data.username
    //   this.authenticated = true
    //   this.notifications = response.data.notifications
    //   if (response.data.profile) {
    //     if (response.data.profile.age_bracket === 0 || response.data.profile.income_bracket === 0) {
    //       this.showWizard = true
    //       this.$emit('wizard-in-progress')
    //       // this.$bvModal.show('modal-financial-profile')  // not working
    //     }
    //   }
    // })
    // .catch(e => {
    //   console.log(e)
    //   this.username = 'unauthenticated'
    //   this.authenticated = false
    //   this.$store.commit('setCurrentUser', {})
    // })
  },
  methods: {
    finishWizard () {
      // FYI theres a bit of a chained-event sequence with these emits
      // ProfileWizard -> Navbar -> AppDashboard
      this.showWizard = false
      this.$emit('fetch-dashboard-data')
    },

    testEnvironment() {
      return process.env.VUE_APP_ENV != 'PROD'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
