<template>
  <div>
    <b-card class="my-3 p-3 box-shadow">
      <div cols="2" class="text-center">
        <b-avatar
          variant="info"
          :src="collection.author.avatar_url"
          size="6rem"
          class="mb-2"
        />
          <!-- :src="https://www.shutterstock.com/image-photo/headshot-portrait-smiling-doctor-wearing-260nw-2002245095.jpg" -->
        <h1 class="c-f"><a :href="collection.author.url">{{ collection.author.full_name }}</a></h1>
      </div>

      <h2 class="input-label c-f">{{  collection.name  }}</h2>
      <div class="input-label c-f">Price: ${{  collection.price_in_dollars  }}</div>
      <p>{{ collection.about_shortened }}</p>
      <div style="position: absolute; top: 3.5rem; right:1rem;">{{ collection.purchased_count }} purchases</div>
      <div
        md="6" cols="12"
        v-for="art in collection.articles"
        v-bind:key="art.id"
      >
        <article-card
          :article="art"
          @remove-article-from-collection="removeArticleFromCollection(art.id)"
        />
      </div>

      <div
        md="6" cols="12"
        v-for="item in collection.items"
        v-bind:key="item.id"
      >
        <item-card :item="item" />
      </div>

      <div
        v-for="ref in collection.referrals"
        v-bind:key="ref.id"
      >
        <referral-card :referral="ref" />
      </div>


    </b-card>
  </div>
</template>

<script>
import ArticleCard from './ArticleCard.vue';
import ItemCard from './ItemCard.vue';
import ReferralCard from './ReferralCard.vue';

export default {
  name: 'CollectionCard',
  components: {
    ArticleCard,
    ItemCard,
    ReferralCard,
  },
  props: [
    'collection'
  ],
  data() {
    return {


    }
  },
  computed: {
    // currentUser() {
      // return this.$store.state.currentUser
    // },
  },
	mounted() {

	},
  created() {

  },
  watch: {
    // optionsSearch: _.debounce(function(addr) { this.getOptions(addr) }, 500), // worked before..
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>


.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

div {
  // font-weight: 100;
  font-family: Larsseit-Light;
}

</style>
