<template>
  <b-container class="mt-4">
    <b-row>
      <b-col cols="2" class="text-center">
        <b-avatar
          :src="user.avatar_url"
          variant="info"
          size="6rem"
          class="mb-2"
        />
        <!-- src="https://www.shutterstock.com/image-photo/headshot-portrait-smiling-doctor-wearing-260nw-2002245095.jpg" -->
      </b-col>
      <b-col cols="10">
        <h1 class="c-f"><a :href="user.public_share_link">{{ user.full_name }}</a>
          <span
            v-if="user.is_demo_user"
            class="p-2 ml-2"
            style="border-radius: 5px; font-size:1rem;background:#ffdd00;color: white;"
          >demo</span>
        </h1>
        <!-- <p class="c-f">{{ user.about }}</p> -->
        <p class="c-f" v-html="user.about"></p>
      </b-col>
    </b-row>
    <b-form-input
      v-if="initialCollectionsCount > 0"
      v-model="searchInput"
      class="d-inline-block mb-3"
      type="text"
      placeholder="Search profession, city, state"
      style="width:100%;">
    </b-form-input>

    <div style="color:gray;font-size:1rem;"> This private collection of referrals is powered by Hints. It has been vetted and verified by our internal teams for its sincerity. Make an account and request access from {{ user.first_name }} today!</div>

    <b-row>
      <b-col
        cols="12"
        md="12"
        class="mb-3 collection-card "
        @click="openModal(collection)"
      >
        <b-card>
          <!-- <a :href="getLink(col)" style="font-size:0.75rem;" class="">{{  col.uuid  }}</a> -->
          <!-- <div class="my-3 py-3" style="border-bottom:1px solid gray;">Collection of referrals</div> -->
          <div class="input-label c-f" style="">{{ collection.name }}</div>
          <div class="c-f mb-3 pb-3" style="border-bottom:1px solid gray;">{{ collection.description }}</div>
          <div class="input-label c-f">Price: ${{  collection.price_in_dollars  }}</div>
          <div style="position: absolute; top: 3.5rem; right:1rem;">{{ collection.purchased_count }} purchases</div>
          <div
            v-for="ref in collection.referrals"
            v-bind:key="ref.id"
          >
            <referral-card :referral="ref" />
          </div>

          <div class="">
            <item-card
              v-for="item in collection.items"
              v-bind:key="item.id"
              :item="item"
              :is-viewable="collection.is_viewable"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="edit-collection-modal" ref="collection-details-modal" :title="selectedCollectionName">
      <b-card>
        <p>This collection was clicked on {{ selectedCollection.viewed_count }} times and purchased {{ selectedCollection.purchased_count }} times - try lowering your price or providing more content</p>
        <p>Do you want to <a :href="editCollectionUrl">edit this collection?</a></p>
      </b-card>
    </b-modal>

    <b-modal id="collection-details-modal" ref="collection-details-modal" :title="selectedCollectionName">
      <b-card>
          <p>{{ selectedCollection.about_shortened }}</p>
          <div
            v-for="ref in selectedCollection.referrals"
            v-bind:key="ref.id"
          >
            <referral-card :referral="ref" />
          </div>
        </b-card>
    </b-modal>

    <!-- <b-modal id="email-modal" ref="email-modal" :title="selectedCollectionName"> -->
    <b-modal id="email-modal" ref="email-modal" title="Hints">
       <payment-details :selectedCollection="selectedCollection" :paymentSetupComplete="this.user.payment_setup_complete" />

        <template #modal-footer>
          <b-button size="sm" variant="outline-secondary" @click="nevermind()">
            Forget it
          </b-button>
        </template>
    </b-modal>
  </b-container>
</template>

<script>
import axios from 'axios';
import VueCookies from 'vue-cookies';
import ReferralCard from '../components/ReferralCard.vue';
import PaymentDetails from '../components/PaymentDetails.vue';
import ItemCard from '../components/ItemCard.vue';
import { createAnalyticsEvent } from '../hooks/helperFunctions'
// import { stripePublishableKey } from '../constants/keys'

export default {
  name: 'CollectionPage',
  components: {
    ReferralCard,
    ItemCard,
    PaymentDetails
  },

	mounted() {
		window.scrollTo(0, 0)
  },

  data() {
    return {
			csrfToken: '',

      initialCollectionsCount: 0,
      collection: {},
      referrals: [],

      modalMessage: '',
      editCollectionUrl: '',
      selectedCollectionId: 0,
      selectedCollection: {},
      selectedCollectionName: '',
      selectedCollectionPrice: '',

      searchInput: '',
      user: {
        full_name: '',
        about: ""
      },

      // STRIPE
      visitorUuid: 0,
      visitorEmail: '',
      stripeUuid: '',
      paymentIntentCreated: false,
      orderNumber: '',
      showPaymentEmailErrorMessage: false,
      paymentEmailErrorMessage: 'Please enter your account email, or the email you want to receive the collections to',
      paymentClientSecret: '',

    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
  },

  created() {
    this.csrfToken = VueCookies.get('csrftoken')


    // const email = this.$route.query.email
    this.getCollection(`${process.env.VUE_APP_HOST}/api/collections/${this.$route.params.uuid}`, true)
  },

  watch: {

  },

  methods: {
    getUser(url) {
      axios.get(url)
      .then(response => {
        console.log('getting user..')
        console.log(response.data)
				this.user = response.data
      })
      .catch(e => {
				console.log(e)
        this.errors.push(e)
      })
    },

    getCollection(url) {
      axios.get(url)
      .then(response => {
        // console.log("logging collections!")
        // console.log(response.data)
				this.collection = response.data
        this.getUser(`${process.env.VUE_APP_HOST}/api/get_user/?user=${response.data.author.uuid}`)

      })
      .catch(e => {
				console.log(e)
        this.errors.push(e)
      })
    },

    createPermissionOrCheckEmail(url){
      axios.get(url)
      .then(response => {
				// this.collections = response.data.results
        // console.log('response..')
        // console.log(response)
        if (response.data.permitted) {
          // setTimeout(() => {  this.$bvModal.hide("email-modal") }, 1000);
          this.modalMessage = response.data.message
          setTimeout(() => {
            this.getCollections(`${process.env.VUE_APP_HOST}/api/collections/?user=${this.$route.params.uuid}&email=${this.email}`)
            this.$bvModal.hide("email-modal") }
          , 1500);

        } else {
          this.modalMessage = response.data.message
        }
      })
      .catch(e => {
				console.log(e)
        this.errors.push(e)
      })
    },

    openModal(collection) {
      console.log("huh??")
      this.selectedCollection = collection
      this.selectedCollectionName = collection.name
      this.selectedCollectionPrice = collection.price_in_dollars
      this.selectedCollectionId = collection.id
      this.editCollectionUrl = process.env.VUE_APP_HOST + '/#/collections/' + collection.uuid + '/edit'

      if (this.currentUser.uuid == collection.author.uuid) {
        this.$bvModal.show("edit-collection-modal")
      } else if (collection.is_viewable) {
        this.$bvModal.show("collection-details-modal")
      } else {
        createAnalyticsEvent('viewed-collection', 'collection', collection.uuid)
        this.$bvModal.show("email-modal")
      }
    },

    nevermind() {
      console.log('nevermind')
      this.$bvModal.hide("email-modal")
      this.visitorEmail = ''
    },


    getLink(col) {
      return process.env.VUE_APP_HOST + '#/users/' + this.$route.params.uuid + '/collections/' + col.uuid
    }
  }
}
</script>

<style lang="scss" scoped>

.referral-card {
  border-radius: 5px;
  // background:lightgreen;
}

.collection-card {
  cursor: pointer;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.bold {
  font-weight: 700;
}

.input-label {
  font-size: 1.5rem;
  // font-weight: 600;
}

.c-f {
  font-family: Larsseit;
}

.profession-name {
  font-size:1.25rem;
}

.collection-status {
  color: white;
  border-radius: 5px;
  width: fit-content;
  padding: 5px;
  position: absolute;
  top: 1rem;
  right: 1rem;

  &.under-review {
    border: 1px solid gold;
    background: gold;
  }
  &.live {
    background: lightseagreen;
    border: 1px solid lightseagreen;
  }

  &.inactive {
    background: lightcoral;
    border: 1px solid lightcoral;
  }
}
</style>
